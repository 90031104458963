import React, { useState,useEffect } from "react";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import BASE_URL from "../constant";
import axios from "axios";
import LoadingPopup from "../Pages/LoadingPopup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Markup } from "interweave";
const AddDonation = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchDonationstext();
        fetchDonations();
        
    }, []);
    const [content, setContent] = useState("");
    const [editedTopic, setEditedTopic] = useState({});
    const [people, setPeople] = useState([
     
    ]);
    const toolbarOptions = [
        [{ 'align': [] }], // Text alignment options: left, center, right, justify
        [{ 'color': [] }, { 'background': [] }], // Text and background color options
        ['bold', 'italic', 'underline'], // Basic formatting options
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // List options
        [{ 'header': [1, 2, 3, false] }], // Header levels
        ['clean'] // Remove formatting button
      ];
      
    const fetchDonations = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_donations.php`);
            console.log(response.data);
            setPeople(response.data);
        } catch (error) {
            console.error("Error fetching topics:", error);
        }
    };
    const fetchDonationstext = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_donations.php/donation_text`);
            console.log(response.data);
            setContent(response.data.donation_text);
        } catch (error) {
            console.error("Error fetching topics:", error);
        }
    };
   
    const handleChange = (value) => {
        setContent(value);
      };
      const updateDonationText = async () => {
        setIsLoading(true);
        try {
            console.log(editedTopic);
            const params = new URLSearchParams();
             params.append('donation_text', content);
            //  params.append('name', editedTopic.name);
           

            const response = await axios.put(`${BASE_URL}/api_donations.php/donation_text`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            fetchDonationstext();
            // setShowModal(false);
            // setEditedTopic({});
            // console.log(top);
            setIsLoading(false);
        } catch (error) {
            console.error('Error updating topic:', error);
            setIsLoading(false);
            throw error;
        }
    };
    const addNewDonation = async (top) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/api_donations.php`, top);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error('Error adding topic:', error);
            throw error;
        }
    };
    
    // Function to update a topic
    const updateDonation = async (top) => {
        setIsLoading(true);
        try {
            console.log(editedTopic);
            const params = new URLSearchParams();
             params.append('id', editedTopic.id);
             params.append('name', editedTopic.name);
           

            const response = await axios.put(`${BASE_URL}/api_donations.php`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            fetchDonations();
            setShowModal(false);
            setEditedTopic({});
            console.log(top);
            setIsLoading(false);
        } catch (error) {
            console.error('Error updating topic:', error);
            setIsLoading(false);
            throw error;
        }
    };
    
    // Function to delete a topic
    const deleteDonation = async (id) => {
        try {
            const params = new URLSearchParams();
            params.append('id', id);
            const response = await axios.delete(`${BASE_URL}/api_donations.php`, {   
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params });
            return response.data;
        } catch (error) {
            console.error('Error deleting topic:', error);
            throw error;
        }
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedName, setSelectedName] = useState("");
    const [newName, setNewName] = useState("");

    const handleEdit = (name) => {
        
        setEditedTopic(name);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedName("");
    };

    const handleDelete = async (id) => {
        try {
            await deleteDonation(id);
            const updatedPeople = people.filter(person => person.id !== id);
            setPeople(updatedPeople);
        } catch (error) {
            console.error('Error deleting topic:', error);
        }
       
    };

    const handleAddPerson = async () => {
        if (newName.trim() !== "" 
       
    ) {
            try {
                const response = await addNewDonation({ name: newName });
                setPeople([...people, { id: response.id, name: newName }]);
                setNewName("");
            } catch (error) {
                console.error('Error adding new  Donation:', error);
            }
        }
       
    };

    return (
        <div className="container mx-auto p-4">
         <label htmlFor="name" className="block text-xl font-medium text-gray-700">
                <strong>Donation Page Content</strong>
                </label>
         {/* <input
                  type="text"
                  id="name"
                  value=""
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                /> */}
                
                <ReactQuill  id="page_desc" className="border" theme="snow"    value={content}
                 modules={{ toolbar: toolbarOptions }}
                    onChange={handleChange}  />
                    <br></br>
                    <div className="flex flex-col items-center justify-center ">
                     <button
                    onClick={updateDonationText}
                    
                    className="px-4 w-[200px] py-2 text-white rounded-md bg-[#6D3815] hover:bg-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                    Add Donation Text
                </button>
                <br></br>
                </div>
            <h2 className="text-2xl font-bold mb-4">Names List</h2>

            {/* Form to add a new person */}
            <div className="flex mb-12">
            <ReactQuill
    theme="snow"
   className="w-full px-3  mr-2 h-auto min-h-[unset] max-h-[unset] resize-none"
    placeholder="Enter new name"
    value={newName}
    onChange={(value) => setNewName(value)} 
    modules={{ toolbar: toolbarOptions }} // ReactQuill returns the value directly
/>


                
                <button
                    onClick={handleAddPerson}
                    className="px-4 w-[200px] py-2 text-white rounded-md bg-[#6D3815] hover:bg-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                    Add New Person
                </button>
            </div>

            <table className="min-w-full  border border-gray-200 rounded-md">
                <thead>
                    <tr className="bg-[#fde5c3c4]">
                        {/* <th className="border-b border-gray-200 px-4 py-2">ID</th> */}
                        <th className="border-b text-left border-gray-200 px-4 py-2">Name</th>
                        <th className="border-b text-left border-gray-200 px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {people.map(person => (
                        <tr key={person.id}>
                            {/* <td className="border-b border-gray-200 px-4 py-2">{person.id}</td> */}
                            <td className="border-b border-gray-200 px-4 py-2">{<Markup content={person.name}/>}</td>
                            <td className="border-b border-gray-200 px-4 py-2">
                                <button onClick={() => handleEdit(person)} className="text-blue-500 hover:text-blue-700 mr-2">
                                    <BsPencilSquare />
                                </button>
                                <button onClick={() => handleDelete(person.id)} className="text-red-500 hover:text-red-700">
                                    <BsTrash />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for editing name */}
            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex w-full sm:items-start">
                                    <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Edit Name</h3>
                                        <div className="mt-2">
                                        <ReactQuill
    theme="snow"
    value={editedTopic.name ?? ''}
    onChange={(value) => setEditedTopic({ ...editedTopic, name: value })}
    modules={{ toolbar: toolbarOptions }}
/>

                                            {/* <input type="text" 

                                            defaultValue={editedTopic.name ?? ''}
                                            //  value={selectedName ?? ''}
                                             onChange={(e) =>
                                                 setEditedTopic({ ...editedTopic, name: e.target.value })
                                             }
                                             className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button type="button"onClick={updateDonation} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    Save
                                </button>
                                <button onClick={handleCloseModal} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
             {isLoading && <LoadingPopup />}
        </div>
        
    );
};

export default AddDonation;
